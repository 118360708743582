.modals {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 20;
    display: grid;
    place-content: center;
    .button-title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 1.75rem;
        @media screen and (max-width: 991px) {
            font-size: 1.25rem;
        }
    }
    .modal-content {
        position: relative;
        background-color: white;
        clip-path: url(#svgClip);
        aspect-ratio: 7 / 4;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 991px) {
            clip-path: unset;
            border-radius: 2em;
            .list-item {
                border-bottom: none;
            }
        }
        .list-item {
            justify-content: center;
            width: 90%;
            padding: 70px 0;
            font-size: 14px;
            @media screen and (max-width: 991px) {
                padding: 20px 0;   
            }
            & > div{
                overflow-y: scroll;
                height: calc(100svh - 150px);
                width: 80%;
                padding: 0 20px;
                @media screen and (max-width: 991px) {
                    width: 100%;
                }
            }
            p{
                margin-bottom: 10px;
                
            }
            ol,ul{
                padding-left: 20px;
                li{
                    margin-bottom: 10px;
                }
            }
        }
        &.bg-yellow {
            background-color: var(--yellow);
        }
        &.aksen {
            position: absolute;
            top: 0.5em;
            left: -3em;
            z-index: -1;
            width: 100%;
            height: 100%;
            border: 1px solid;
            transform: scale(0.9) scaleY(1.1) rotate(-3deg);
        }
    }
    .close-modal {
        position: absolute;
        right: 12%;
        top: 9%;
        z-index: 2;
        height: 40px;
        width: 40px;
        background-color: var(--blue);
        border-radius: 100%;
        color: white;
        border: none;
        transform-origin: center;
        transform: translate(50%, -50%);
        font-size: 20px;
        transition: all .3s ease;
        cursor: pointer;
        &:hover{
            background-color: var(--orange);
            animation: closemodal_  .45s;
        }
        @media screen and (max-width: 991px) {
            right: 4%;
            top: 0;
        }
    }
    .modalTop{
        @media screen and (max-width: 991px) {
            margin-top: 2em;
        }
    }
    .imageclip {
        @media screen and (max-width: 991px) {
            aspect-ratio: 16/10;
        }
      }
}
.popupmodal-overlay{
    background-color: rgba(0, 0, 0, 0.8);
    .popup-content{
        max-width: 700px;
        width: 85%;
        // aspect-ratio: 3/2;
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            cursor: pointer;
            height: 100%;
            width: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .close{
        position: absolute;
        right: -15px;
        top: -15px;
        width: 35px;
        height: 35px;
        background: var(--orange);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 50%;
        cursor: pointer;
        &:hover{
            transform: scale(1.05);
            transition: .4s ease;
        }
    }
}

.popup-content {
    padding: 0;
    border: none;
    width: 80%;
    @media screen and (max-width: 991px) {
        width: 90%;
    }
  }
@keyframes closemodal_ {
    0% {
        transform: translate(50%, -50%) scale(1.15);
      }
      33% {
        transform: translate(50%, -50%) scale(0.9);
      }
      66% {
        transform: translate(50%, -50%) scale(1.1);
      }
      100% {
        transform: translate(50%, -50%) scale(1.0);
      }
}
