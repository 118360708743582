.button-bubble {
    width: 180px;
    min-height: 60px;
    padding: 10px 15px;
    // background: var(--yellow);
    cursor: pointer;
    background-color: transparent;
    // border-radius: 25px;
    position: relative;
    border: 0;
}

.button-bubble .text {
    color: var(--blue);
    font-weight: bold;
    letter-spacing: 1px;
    font-family: "Poppins";
    font-size: 16px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    z-index: 1;
    .icon {
        flex: 0 0 50px;
        margin-right: 10px;
        display: flex;
    }
    .button-text {
        text-align: left;
    }
}

.button-bubble span.shape {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background-color: var(--yellow);
    background: url("./background.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
}

.button-bubble span span {
    content: "";
    position: absolute;
    margin: auto;
    height: 8px;
    width: 2px;
    background: var(--yellow);
    opacity: 0;
}

.button-bubble span span:nth-of-type(1) {
    top: -5px;
    left: 0;
    right: 0;
}

.button-bubble span span:nth-of-type(2) {
    bottom: -5px;
    left: 0;
    right: 0;
}

.button-bubble span span:nth-of-type(3) {
    top: 0;
    bottom: 0;
    left: -3px;
}

.button-bubble span span:nth-of-type(4) {
    top: 0;
    bottom: 0;
    right: -3px;
}

.button-bubble span span:nth-of-type(5) {
    top: -5px;
    left: -70%;
    right: 0;
    transform: rotate(-30deg);
}

.button-bubble span span:nth-of-type(6) {
    top: -5px;
    left: 0;
    right: -70%;
    transform: rotate(30deg);
}

.button-bubble span span:nth-of-type(7) {
    bottom: -5px;
    right: 0;
    left: -70%;
    transform: rotate(30deg);
}

.button-bubble span span:nth-of-type(8) {
    bottom: -5px;
    left: 0;
    right: -70%;
    transform: rotate(-30deg);
}

/* let's animate this */
@keyframes bounce {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.button-container:hover {
    & > .button-bubble {
        animation: bounce 0.5s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(1) {
        animation: anim1 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(2) {
        animation: anim2 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(3) {
        animation: anim3 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(4) {
        animation: anim4 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(5) {
        animation: anim5 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(6) {
        animation: anim6 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(7) {
        animation: anim7 0.6s ease-out 1;
    }

    & > .button-bubble span span:nth-of-type(8) {
        animation: anim8 0.6s ease-out 1;
    }
}

.button-bubble:hover {
    animation: bounce 0.5s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(1) {
    animation: anim1 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(2) {
    animation: anim2 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(3) {
    animation: anim3 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(4) {
    animation: anim4 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(5) {
    animation: anim5 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(6) {
    animation: anim6 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(7) {
    animation: anim7 0.6s ease-out 1;
}

.button-bubble:hover span span:nth-of-type(8) {
    animation: anim8 0.6s ease-out 1;
}

@keyframes anim1 {
    0% {
        transform: scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: translateY(-15px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: translateY(-20px) scaleY(0.5);
        opacity: 0;
    }
}

@keyframes anim2 {
    0% {
        transform: scaleY(1);
        opacity: 0;
    }

    50% {
        transform: translateY(10px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: translateY(20px) scaleY(0.5);
        opacity: 0;
    }
}

@keyframes anim3 {
    0% {
        transform: rotate(90deg) scaleX(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(90deg) translateY(10px) scaleX(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(90deg) translateY(20px) scaleX(0.5);
        opacity: 0;
    }
}

@keyframes anim4 {
    0% {
        transform: rotate(90deg) scaleX(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(90deg) translateY(-15px) scaleX(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(90deg) translateY(-20px) scaleX(0.5);
        opacity: 0;
    }
}

@keyframes anim5 {
    0% {
        transform: rotate(-30deg) scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(-30deg) translateY(-8px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(-30deg) translateY(-16px) scaleY(0.5);
        opacity: 0;
    }
}

@keyframes anim6 {
    0% {
        transform: rotate(30deg) scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(30deg) translateY(-8px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(30deg) translateY(-16px) scaleY(0.5);
        opacity: 0;
    }
}

@keyframes anim7 {
    0% {
        transform: rotate(30deg) scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(30deg) translateY(8px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(30deg) translateY(16px) scaleY(0.5);
        opacity: 0;
    }
}

@keyframes anim8 {
    0% {
        transform: rotate(-30deg) scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: rotate(-30deg) translateY(8px) scaleY(1.4);
        opacity: 1;
    }

    100% {
        transform: rotate(-30deg) translateY(16px) scaleY(0.5);
        opacity: 0;
    }
}
