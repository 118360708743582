@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-Bold.woff') format('woff'),
    url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
    url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sallomae';
  src: url('./assets/fonts/Sallomae.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



:root{
  --yellow: #f9ee5e;
  --blue: #3d60a2;
  --orange: #df6b48;
  --lightblue: #c9e3f4;
  --green: #75862a;
  --lightgreen: #cadc7d;
  --global-container-width: 1100px;
}

img{
  display: block;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
}

*,
::after,
::before{
  box-sizing: border-box;
}

