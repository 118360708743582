.five-minutes {
    width: 200px;
    margin-bottom: 1em;
}

.accomodation-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    img {
        width: 30%;
    }
    p {
        width: 60%;
    }
    @media screen and (max-width: 767px) {
        justify-content: center;
        img {
            width: 50%;
        }
        p {
            width: 90%;
        }
    }
}

.see-more {
    display: flex;
    align-items: center;
    color: var(--blue);
    justify-content: center;
    transition: all .3s ease;
    width: fit-content;
    .arrow {
        height: 30px;
        width: 30px;
        background-color: var(--blue);
        border-radius: 50%;
        color: white;
        display: flex;
        font-size: 70%;
        align-items: center;
        justify-content: center;
        margin-left: .5em;
        transition: all .3s ease;
    }
    &:hover{
        color: var(--orange);
        .arrow{
            background-color: var(--orange);
            animation: toBottom .5s 1;
        }
    }
}

@keyframes toBottom {
    50%{
        transform: translateY(5px);
    }
    100%{
        transform: translateY(0);
    }
}
