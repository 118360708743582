.transition-page{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--blue);
    z-index: 10;
    overflow: hidden;
    transform: translateY(-100%);
    // transition: 1s ease-in-out;
    &.blue-light{
        background-color: #7ac4e5;
    }
    &.white{
        background-color: rgba(255, 255, 255, 0.923);
        // backdrop-filter: blur(4px);
    }
}