.ticket-circular-slider-section {
    .navigation-button {
        button {
            z-index: 1;
            border: 0;
            background-color: transparent;

            .arrow {
                height: 70px;
                width: 70px;
                background-color: var(--orange);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    animation: wiggle 0.8s ease-out 0s 1;
                }
                @media screen and (max-width: 767px) {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        .button-next {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);

            .arrow {
                &:after {
                    font-family: "Font Awesome 6 Pro";
                    color: white;
                    font-weight: 900;
                    font-size: 18px;
                    content: "\f105";
                }
            }
        }

        .button-prev {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);

            .arrow {
                &:after {
                    font-family: "Font Awesome 6 Pro";
                    color: white;
                    font-weight: 900;
                    font-size: 18px;
                    content: "\f104";
                }
            }
        }
    }
    .wave {
        position: relative;
        img.img-wave {
            width: 100%;
            // transform: translateY(-1px);
            // max-height: 200px;
        }
        &.wave-top {
            .spacer {
                background-color: white;
                height: 100px;
            }
        }
        .ornaments {
            height: unset;
            .dolphin {
                width: 100px;
                bottom: unset;
                top: 50px;
                left: 20px;
                transform: rotateZ(-20deg);
            }
            .splash {
                bottom: 0px;
                width: 150px;
                @media (max-width: 767px) {
                    bottom: unset;
                    top: 10px;
                    display: none;
                }
            }
        }
    }
    .slider-bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        & > .single-bullet {
            background-color: var(--blue);
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin: 0 4px;
            position: relative;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 3px;
                transition: all 0.3s;
                background-color: var(--yellow);
                opacity: 0;
            }
            &.active {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}

.ticket-circular-slider-container {
    height: 300px;
    // overflow: hidden;
    .box-container {
        display: flex;
        position: absolute;
        overflow: visible;
        left: 50%;
        transform-origin: center 2300px;
        transition: -webkit-transform 0.6s cubic-bezier(0.14, 0.85, 0.4, 1.33);
        transition: transform 0.6s cubic-bezier(0.14, 0.85, 0.4, 1.33);
        transition: transform 0.6s cubic-bezier(0.14, 0.85, 0.4, 1.33),
            -webkit-transform 0.6s cubic-bezier(0.14, 0.85, 0.4, 1.33);
        &.dragging {
            transition: none;
        }
    }
    .box {
        a {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            user-select: none;
            -webkit-user-drag: none;
        }
        cursor: pointer;
        width: 460px;
        // height: 600px;
        // background-color: red;
        transform-origin: center 2300px;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: white;
        .image,
        svg,
        img {
            width: 100%;
            user-select: none;
        }
        &:hover {
            .image {
                -webkit-animation: ticket-jelly 0.5s 1;
                -moz-animation: ticket-jelly 0.5s 1;
                animation: ticket-jelly 0.5s 1;
            }
        }
        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
                transform: translateX(-50%) rotate(#{($i - 1) * 15}deg);
            }
        }
    }
    @media screen and (max-width: 767px) {
        height: 200px;
        .box {
            width: 280px;
            @for $i from 1 through 12 {
                &:nth-child(#{$i}) {
                    transform: translateX(-50%) rotate(#{($i - 1) * 8}deg);
                }
            }
        }
    }
}

@keyframes ticket-jelly {
    0%,
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    25% {
        -webkit-transform: scale(0.9, 1.1);
        -moz-transform: scale(0.9, 1.1);
        -ms-transform: scale(0.9, 1.1);
        -o-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
    }
    50% {
        -webkit-transform: scale(1.1, 0.9);
        -moz-transform: scale(1.1, 0.9);
        -ms-transform: scale(1.1, 0.9);
        -o-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
    }
    75% {
        -webkit-transform: scale(0.95, 1.05);
        -moz-transform: scale(0.95, 1.05);
        -ms-transform: scale(0.95, 1.05);
        -o-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
    }
}
