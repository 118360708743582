.attraction-div {
    width: 100%;
    .attracrion-list-other {
        position: relative;
        width: 100%;
    }
    .main-image {
        width: 70%;
        border-radius: 1em;
        overflow: hidden;
        aspect-ratio: 3/2;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.3s ease;
            &:hover {
                transform: scale(1.0125);
            }
        }
    }
    .card-desc {
        background-color: white;
        padding: 2em 3em;
        border-radius: 50px / 50%;
        position: absolute;
        bottom: -40px;
        right: 0;
        width: 60%;
        @media screen and (max-width: 767px) {
            position: relative;
            width: 100%;
            margin-top: -3.5em;
            padding: 3em 2.5em;
            // z-index: -1;
            border-radius: 0;
            border-bottom-left-radius: 1em;
            border-bottom-right-radius: 1em;
        }
        .title-other {
            color: var(--orange);
            transition: all 0.3s ease;
            &:hover {
                letter-spacing: 0.7px;
            }
        }
    }
    .swiper {
        padding-bottom: 4em;
        margin-bottom: -225px;
        .swiper-button-prev,
        .swiper-button-next {
            background-color: var(--orange);
            box-shadow: 0px 0px 20px -15px black;
            &:after {
                color: white;
            }
        }
        @media screen and (max-width: 767px) {
            overflow: unset;
            margin-bottom: 0;
            .swiper-button-next {
                right: 0;
                transform: translateX(30%);
            }
            .swiper-button-prev{
                left: 0;
                transform: translateX(-30%);
            }
        }
    }
}

.div-content-other {
    transform: translateY(-250px);
    @media screen and (max-width: 767px) {
        transform: translateY(0);
    }
}

.section-other-attraction {
    margin-top: 100px;
    @media screen and (max-width: 767px) {
        margin-top: 0;
    }
}
