.promotion-slider-section{
    padding: 30px 0 0 0;
    overflow: hidden;
    .swiper{
        margin-bottom: 50px;
    }
    .slider-container{
        width: 100%;
        // max-width: var(--global-container-width);
        margin: 0 auto;
        h3{
            font-family: Poppins;
            font-size: 32px;
            font-weight: bold;
            // color: var(--blue);
            // margin-bottom: 30px;
        }
        .swiper-horizontal{
            overflow: visible;
        }
    }
    .single-promo{
        .image{
            user-select: none;
            position: relative;
            overflow: hidden;
            border-radius: 15px;
            img{
                width: 100%;
            }
            .hover-effect{
                .bubble{
                    border-radius: 50%;
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    transition: all 0.5s;
                    transform: scale(0);
                }
                .bubble-top{
                    top: -50px;
                    left: -50px;
                    background-color: #52c9e7;
                }
                .bubble-bottom{
                    bottom: -50px;
                    right: -50px;
                    background-color: var(--blue);
                }
                .button-container{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: all 0.4s;
                }
            }
        }
        &:hover{
            .image{
                .hover-effect{
                    .bubble{
                        transform: scale(15);
                    }
                    .button-container{
                        opacity: 1;
                        button{
                            animation: bounce 0.5s 0.2s ease-out 1;
                        }
                    }
                }
            }
        }
    }
    .swiper-scrollbar-drag{
        background-color: var(--blue);
    }
    .swiper-scrollbar{
        width: 100%;
        left: 0px;
        background-color: var(--yellow);
    }
    .swiper-horizontal>.swiper-scrollbar,
    .swiper-scrollbar.swiper-scrollbar-horizontal{
        // position: unset;
        // margin-top: 40px;
        height: 15px;
        bottom: -50px;
    }
}