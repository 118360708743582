.ticket-list{
    $gap: 15px;
    display: flex;
    justify-content: space-between;
    gap: $gap;
    margin-bottom: 4.5em;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        padding-bottom: 2em;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 2em;
    }
    &>div{
        &:nth-child(1){
            width: calc(40% - $gap);
        }
        &:nth-child(2){
            width: calc(60% - $gap);
        }
        @media screen and (max-width: 767px) {
            width: 100% !important;
        }
    }
    img{
        width: 100%;
        transition: all .3s ease;
        &:hover{
            // -webkit-animation: ticket-jelly 0.5s 1;
            // -moz-animation: ticket-jelly 0.5s 1;
            // animation: ticket-jelly 0.5s 1;
        }
    }
    .ticket-name{
        color: var(--blue);
        transition: all .35s ease;
        &:hover{
            // color: var(--orange);
        }
    }
    table{
        width: 75%;
        margin-bottom: 1em;
    }
    p{
        margin-bottom: 1em;
    }
}

@keyframes ticket-jelly {
    0%,
    100% {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    25% {
        -webkit-transform: scale(0.9, 1.1);
        -moz-transform: scale(0.9, 1.1);
        -ms-transform: scale(0.9, 1.1);
        -o-transform: scale(0.9, 1.1);
        transform: scale(0.9, 1.1);
    }
    50% {
        -webkit-transform: scale(1.1, 0.9);
        -moz-transform: scale(1.1, 0.9);
        -ms-transform: scale(1.1, 0.9);
        -o-transform: scale(1.1, 0.9);
        transform: scale(1.1, 0.9);
    }
    75% {
        -webkit-transform: scale(0.95, 1.05);
        -moz-transform: scale(0.95, 1.05);
        -ms-transform: scale(0.95, 1.05);
        -o-transform: scale(0.95, 1.05);
        transform: scale(0.95, 1.05);
    }
}