.imageclip {
    border-radius: 30% 29% 29% 27% / 30% 35% 33% 28%;
    overflow: hidden;
    aspect-ratio: 4 / 3;
    // background-color: #f2f2f2;
    &:not(.mapp) {
        div {
            height: 100%;
        }
        &:hover {
            animation: squaze 0.6s;
        }
        img {
            clip-path: url(#svgClip);
        }
    }
    img,
    #div-for-map{
        min-width: 300px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
    #div-for-map {
        // clip-path: url(#svgClip);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f1f1f1;
        min-width: 500px;
        aspect-ratio: 4 / 3;
    }
}

@keyframes squaze {
    // 0% {
    //     transform: translateY(1em);
    // }
    // 25% {
    //     transform: translateY(1.2em);
    // }
    // 50% {
    //     transform: translateY(0.9em);
    // }
    // 75% {
    //     transform: translateY(1.1em);
    // }
    // 100% {
    //     transform: translateY(1em);
    // }
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
