.insta-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    img{
        width: 100%;
    }
    .single-image{
        border-radius: 10px;
        overflow: hidden;
    }
    @media screen and (max-width: 991px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
       gap: 10px;
    }
}
.turtle{
    position: absolute;
    right: 0px;
    bottom: -100px;
    transform: rotateZ(5deg);
    img{
        width: 120px;
    }
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.insta-slide{
    padding: 0 15vw;
    position: relative;
    overflow: hidden;
    .swiper-wrapper{
        -webkit-transition-timing-function: linear !important;
        -o-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
    }
    .swiper{
        overflow: visible;
    }
    .single-image{
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        img{
            width: 100%;
        }
        .hover-effect{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            .bubble{
                width: 185%;
                height: 0%;
                border-radius: 50% 50% 0 0;
                background-color: #52c9e7;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
                transition: all 0.4s cubic-bezier(0.14, 0.85, 0.4, 1.33);
                .text{
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-family: Poppins;
                    font-weight: 900;
                    color: white;
                    font-size: 18px;
                }
            }
        }
        &:hover{
            .hover-effect{
                .bubble{
                    // width: 200%;
                    height: 100px;
                    bottom: 0;
                    animation: insta-bubble-anim 0.4s infinite alternate linear;
                    // transition;
                }
            }
        }
    }
}

@keyframes insta-bubble-anim {
    0%{
        width: 135%;
    }
    100%{
        width: 200%;
    }    
}