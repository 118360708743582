.list-map-card{
    position: relative;
    padding: 2.5em 1.5em 1.5em;
    border-radius: 2em / 40%;
    font-weight: 600;
    column-count: 3;
    height: min-content;
    .button-title{
        color: var(--yellow) ;
        @media screen and (max-width: 767px) {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    @media screen and (max-width: 991px) {
        column-count: 2;
    }
    @media screen and (max-width: 767px) {
        border-radius: 2em;
        column-count: 1;
        margin-bottom: 3em;
    }
    .list-of-items{
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        font-size: 14px;
        line-height: 1;
        min-height: 30px;
    }
    .listName{
        width: calc(100% - 25px);
    }
    .bullets{
        width: 25px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        margin-right: 10px;
        color: var(--blue);
        display: grid;
        place-items: center;
        img{
            width: 100%;
            object-fit: cover;
            // padding: 6px;
        }
    }
    // background-color: yellow;
}
.map-zoom{
    display: flex;
    justify-content: end;
    button{
        width: 60px;
        height: 60px;
        border: none;
        outline: none;
        background-color: white;
        color: var(--orange);
        font-size: 1.5rem;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 7px;
        cursor: pointer;
        transition: all .35s ease;
        @media screen and (max-width: 767px) {
            width: 40px;
            height: 40px;
            font-size: 1rem;
            margin: 3px;
        }
        &:not(:disabled){
            &:hover{
                background-color: var(--orange);
                color: white;
            }
        }
        &:disabled{
            opacity: .7;
        }
    }
}