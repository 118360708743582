.accomodation-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .accomodation-item {
        // background-color: red;
        width: calc(50% - 30px);
        min-height: 200px;
        aspect-ratio: 5 / 3;
        border-radius: 15% / 45%;
        position: relative;
        background-size: 100%;
        background-position: center;
        overflow: hidden;
        transition: all 0.35s ease;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.35);
        }
        &:hover,
        &:focus {
            background-size: 125%;
            .book span {
                // transform: rotateX(90deg) translateY(-22px);
                transform:translateY(100%);
                &:before {
                    color:var(--orange);
                }
            }
        }
        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }
    .title {
        width: 100%;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: white;
        z-index: 2;
        line-height: 1.2;
        position: absolute;
        bottom: 0.5em;
        left: 0;
        width: 100%;
    }
    .accomodation-place {
        width: 80%;
        margin: auto;
        height: 100%;
    }
    .book {
        position: absolute;
        top: 1.5em;
        right: 1em;
        transform: rotate(-5deg) scale(1.2);
        overflow: hidden;
        span {
            position: relative;
            display: inline-block;
            padding: 0;
            transition: transform 0.3s;
            transform-origin: 50% 0;
            transform-style: preserve-3d;
            &:before {
                content: attr(data-text);
                position: absolute;
                top: -100%;
                left: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease;
                // transform: rotateX(-90deg);
                // transform-origin: 50% 0;
                text-align: center;
            }
        }
    }
}
.all-day-img {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 75%;
    img {
        max-width: 32%;
        border-radius: 10px;
        &:nth-child(1) {
            transform: rotate(-4deg) translateY(0.7em);
        }
        &:nth-child(3) {
            transform: rotate(4deg) translateY(0.7em);
        }
    }
}
