.wave-container {
    overflow: hidden;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.section-wave {
    // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
    background-image: url(./wave.svg);
    // background-size: 3000px 300px;
    // background-position: top;
    background-position: 0 0;
    background-repeat: repeat-x;
    background-size: 1131px 140px;
    width: 4524px;
    height: 140px;
    animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    &.yellow{
        background-image: url(./wave-yellow.svg);
    }
    @media (max-width: 991px) {
        background-size: 808px 100px;
        height: 100px;
    }
    @media screen and (max-width: 767px) {
        height: 35px;
        background-size: 283px 40px;
    }
}

:root{
    --wave-transform-x: -1131px;
}

@media screen and (max-width: 767px) {
    :root{
        --wave-transform-x: -565.5px;
    }
}

@keyframes wave {
    0% {
        // margin-left: 0;
        background-position: 0 0;
    }

    100% {
        background-position: 200% 0;
        // margin-left: var(--wave-transform-x);   
    }
}

@keyframes swell {

    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }

    50% {
        transform: translate3d(0, 5px, 0);
    }
}