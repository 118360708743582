.banner {
    // width: 100%;
    width: 101%;
    height: 80vh;
    min-height: 400px;
    max-height: 650px;
    display: grid;
    place-items: center;
    background-color: #f2f2f2;
    background-size: cover;
    position: relative;
    background-position: center;
    z-index:-1;
    .text-banner {
        font-size: 2.25rem;
        font-family: "Poppins", Arial, Helvetica, sans-serif;
        color: white;
        position: relative;
        z-index: 1;
        text-align: center;
    }
    &.no-overlay {
        &:after {
            content: unset;
        }
    }
    &:after {
        content: "";
        position: absolute;
        // z-index: 1;
        background-color: #00000069;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.bottom-wave-banner,
.top-wave-banner {
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 103%;
    height: 130px;
    z-index: 1;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        height: 100px;
    }
    @media screen and (max-width: 520px) {
        height: 50px;
    }
    .wavewhite,
    .waveblue,
    .waveyellow {
        background-repeat: repeat-x;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-size: 100%;
        width: 101%;
        animation: wave 9s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
        @media screen and (max-width: 767px) {
            width: 109%;
        }
    }
    .wavewhite {
        background-image: url("./wave-white.svg");
    }
    .waveblue {
        background-image: url("./wave-blue.svg");
        transform: scaleX(-1);
    }
}

.top-wave-banner {
    bottom: unset;
    top: 0;
    transform: translateY(-100%);
}

:root {
    --wave-transform-x-banner: -100vw;
}

@keyframes wave {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: var(--wave-transform-x-banner);
    }
}
