.circular-slider-section{
    .navigation-button {
        button {
            z-index: 2;
            border: 0;
            background-color: transparent;

            .arrow {
                height: 70px;
                width: 70px;
                background-color: var(--yellow);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    animation: wiggle 0.8s ease-out 0s 1;
                }
                @media screen and (max-width: 767px) {
                    height: 40px;
                    width: 40px;                    
                }
            }
        }

        .button-next {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);

            .arrow {
                &:after {
                    font-family: "Font Awesome 6 Pro";
                    color: var(--blue);
                    font-weight: 900;
                    font-size: 18px;
                    content: "\f105";
                }
            }
        }

        .button-prev {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);

            .arrow {
                &:after {
                    font-family: "Font Awesome 6 Pro";
                    color: var(--blue);
                    font-weight: 900;
                    font-size: 18px;
                    content: "\f104";
                }
            }
        }
    }
    .attraction-title {
        // transition: opacity 0.3s;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            display: inline-flex;
        }
        &.show {
            // transition-duration: 0s;
            // opacity: 1;
            animation: show-title 0.3s ease 0s forwards;
        }
    }
    .ride-data-container{
        max-width: 900px;
        margin: 0 auto;
        padding: 0 15px;
        position: relative;
    }
    .selected-arrow{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -30px;
        .arrow{
            animation: selected-arrow-animation 1.3s infinite;
            i{
                color: var(--blue);
                font-size: 36px;
                @media screen and (max-width: 767px) {
                    font-size: 25px;
                }
            }
            &:after{
                content: "";
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: var(--yellow);
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                @media screen and (max-width: 767px) {
                    width: 7px;
                    height: 7px;
                }
            }
        }
    }
    .description {
        margin-bottom: 20px;
        .ride-data {
            display: flex;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 30px;
            &.detail{
                flex-direction: column;

            }
            & > div{
                flex: 0 0 33.33%;
                padding: 0 15px;
            }
            .age {
                display: flex;
                align-items: center;
                .icon {
                    flex: 0 0 100px;
                    margin-right: 15px;
                    img {
                        width: 100%;
                    }
                }
                .text {
                    .age-num {
                        font-family: Poppins;
                        font-weight: bold;
                        color: var(--blue);
                        font-size: 26px;
                        line-height: 1;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 1.4;
                    }
                }
            }
            .stats{
                .single-stat{
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    .name{
                        font-family: Poppins;
                        color: var(--blue);
                        font-size: 20px;
                        flex: 0 0 80px;
                        margin-right: 20px;
                    }
                    .bar{
                        flex: 1 1 100%;
                        padding: 3px;
                        border: solid 1px var(--blue);
                        position: relative;
                        .bar-inner{
                            transition: all 0.4s ease-out;
                            height: 18px;
                            background-color: #52c9e7;
                        }
                        .bar-indicator{
                            position: absolute;
                            bottom: 0;
                            left: 3px;
                            width: calc(100% - 6px);
                            display: flex;
                            justify-content: space-evenly;
                            & > div{
                                height: 15px;
                                width: 1px;
                                background-color: var(--blue);
                                // position: absolute;
                                // bottom: 0;
                            }
                        }
                    }
                }
            }
            .type {
                font-family: Poppins;
                color: var(--blue);
                font-size: 20px;
            }
            @media screen and (max-width: 991px) {
                // flex-direction: column;
                // align-items: center;
                // justify-content: center;
                flex-wrap: wrap;
                &>div {
                    flex: 0 0 50%;
                    width: 100%;
                    max-width: 400px;
                }
                .age,.stats{
                    margin-bottom: 30px;
                }
                .age{
                    justify-content: center;
                }
            }
            @media screen and (max-width: 991px) {
                &.detail{
                    justify-content: start;
                    align-items: unset;
                    width: 100%;
                }
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                &>div {
                    flex: auto;
                    width: 100%;
                    max-width: 400px;
                }
                .age,.stats{
                    margin-bottom: 30px;
                }
                .age{
                    justify-content: center;
                }
            }
        }
        .description-text{
            h3{
                font-family: Poppins;
                color: var(--blue);
                font-size: 26px;
                font-weight: 600;
            }
            p{
                font-size: 20px;
                @media screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }
    .slider-bullet{
        display: flex;
        align-items: center;
        justify-content: center;
        & > .single-bullet{
            background-color: var(--blue);
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin: 0 4px;
            position: relative;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 3px;
                transition: all 0.3s;
                background-color: var(--yellow);
                opacity: 0;
            }
            &.active{
                &:after{
                    opacity: 1;
                }
            }
        }
    }
}

.circular-slider-container{
    height: 450px;
    position: relative;
    // overflow: hidden;
    .box-container{
        height: 450px;
        display: flex;
        position: absolute;
        overflow: visible;
        left: 50%;
        transform-origin: center 3100px;
        transition: -webkit-transform .6s cubic-bezier(.14, .85, .4, 1.33);
        transition: transform .6s cubic-bezier(.14, .85, .4, 1.33);
        transition: transform .6s cubic-bezier(.14, .85, .4, 1.33), -webkit-transform .6s cubic-bezier(.14, .85, .4, 1.33);
        &.dragging{
            transition: none;
        }
    }
    @media screen and (max-width: 767px) {
        height: 350px;
    }
    .box{
        width: 320px;
        // height: 600px;
        // background-color: red;
        transform-origin: center 3100px;
        position: absolute;
        top: 0;
        left: 0;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: white;
        .image,svg,img{
            width: 100%;
        }
        .image{
            cursor: pointer;
            image{
                width: 100%;
                height: auto;
                transition: all .3s ease;
                &:hover{
                    opacity: .8;
                }
            }
        }
        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                transform: translateX(-50%) rotate(#{($i - 1)*7}deg);
            }
        }

        @media screen and (max-width: 767px) {
            width: 240px;
            @for $i from 1 through 12 {
                &:nth-child(#{$i}) {
                    transform: translateX(-50%) rotate(#{($i - 1)*5}deg);
                }
            }
        }
    }
    
}

.ride-slider{
    height: 500px;
    overflow: visible;
    .swiper-wrapper{
        position: absolute;
        left: 50%;
        top: 0px;
        width: 0px;
        transform-origin: center 3100px;
        transition: -webkit-transform .6s cubic-bezier(.14, .85, .4, 1.33) !important;
        transition: transform .6s cubic-bezier(.14, .85, .4, 1.33) !important;
        transition: transform .6s cubic-bezier(.14, .85, .4, 1.33), -webkit-transform .6s cubic-bezier(.14, .85, .4, 1.33) !important;
    }
    .swiper-slide{
        transform-origin: center 3100px;
        position: absolute;
        left: 0;
        top: 0;
        img{
            width: 100%;
        }
        @for $i from 1 through 20 {
            &:nth-child(#{$i}) {
                transform: translateX(-50%) rotate(#{($i - 1)*8}deg);
            }
        }
    }
}

@keyframes show-title {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
    
}

@keyframes selected-arrow-animation {
    0%{
        opacity: 0;
    }
    20%{
        transform: translateY(0px);
    }
    80%{
        opacity: 1;
    }
    100%{
        transform: translateY(-15px);
    }
}