@keyframes anvil {
0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
}
1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
}
100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
}
}
.popup-content {
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
.video-popup-overlay{
    padding: 15px;
}
.video-popup-content{
    background-color: var(--yellow);
    border: 0;
    border-radius: 10px;
    padding: 50px 30px 30px 30px;
    width: 100%;
    max-width: 800px;
    @media (max-width: 767px) {
        padding: 30px 10px 10px 10px;
    }
    .close-button{
        position: absolute;
        top: -15px;
        right: -15px;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: var(--blue);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;
    }
    .modal{
        .heading{
            font-family: Poppins;
            font-weight: 900;
            text-align: center;
            margin-bottom: 30px;
            h3{
                color: #52c9e7;
                font-size: 32px;
            }
            @media (max-width: 767px) {
                margin-bottom: 15px;
                h3{
                    font-size: 20px;
                }
            }
        }
        .iframe-container{
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            iframe{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}

.trailer-video{
    position: relative;
    // margin-bottom: -100px;
    overflow: hidden;
    .top-curve{
        position: absolute;
        top: 0;
        left: 0;
        // width: 100vw;
        width: 100%;
        display: block;
        img{
            width: 105%;
            margin-top: -2px;
        }
    }
    .bordered{
        border-radius: 1em;
    }
    .play-button{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
        i {
            font-size: 50px;
            color: var(--blue);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            z-index: 1;
            transition: font-size 0.5s;
            @media (max-width: 991px) {
                font-size: 40px;
            }
            @media (max-width: 767px) {
                font-size: 30px;
            }
        }
        &:hover{
            i{
                font-size: 80px;
                @media (max-width: 991px) {
                    font-size: 60px;
                }
                @media (max-width: 767px) {
                    font-size: 40px;
                }
            }
        }
    }
    .video{
    aspect-ratio: 16/9;

        & > img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.bubble-circle {
    animation: rotating 15s linear infinite;
    background: rgba(255,255,255,0.8);
    border-radius: 335.383px 406px 323.168px 384.988px;
    bottom: 0;
    display: block;
    margin: 50px auto;
    transition: background-color 2s ease-out;
    height: 150px;
    width: 150px;
    transition: all 2s linear;
    @media (max-width: 991px) {
        height: 150px;
        width: 150px;
    }
    @media (max-width: 767px) {
        height: 75px;
        width: 75px;
    }
}

@-webkit-keyframes rotating

/* Safari and Chrome */
    {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 15s linear infinite;
    -moz-animation: rotating 15s linear infinite;
    -ms-animation: rotating 15s linear infinite;
    -o-animation: rotating 15s linear infinite;
    animation: rotating 15s linear infinite;
}