.list-item {
    $gap: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $gap;
    & > div {
        width: calc(50% - $gap);
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 767px) {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 3em;
    }
}
