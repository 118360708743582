.accordion {
    margin-bottom: 2.5em;
    .accordion-title {
        background-color: var(--blue);
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1em 2em;
        font-size: 1.25rem;
        font-weight: bold;
        cursor: pointer;
        border-radius: 3% / 70%;
        position: relative;
        .accordion-title-text {
            max-width: 75%;
        }
        .arrow {
            transition: all 0.3s ease;
            transform-origin: center;
            &.rotate {
                transform: rotate(180deg);
            }
        }
        .rounded-top{
            position: absolute;
            left: 50%;
            top: 3px;
            width: 96%;
            // height: -webkit-fit-content;
            // height: fit-content;
            transform: translate(-50%, -95%) scaleY(.9);
        }
        .rounded-bottom{
            position: absolute;
            left: 50%;
            bottom: 3px;
            width: 96%;
            // height: -webkit-fit-content;
            // height: fit-content;
            transform: translate(-50%, 95%) scaleY(-0.9);
        }
        path{
            fill: var(--blue);
        }
    }
    .accordion-content {
        padding: 0 1.5em;
        color: var(--yellow);
        font-weight: 600;
        background-color: var(--yellow);
        border-bottom-left-radius: 1.5em;
        border-bottom-right-radius: 1.5em;
        height: 0;
        overflow: hidden;
        width: 96%;
        margin: auto;
        transition: padding 0.3s ease, color 0.3s ease;
        &.open {
            height: auto;
            padding: 1.5em;
            color: var(--blue);
        }
    }
}
