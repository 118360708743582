.info-venue {
    .venue-list {
        margin-bottom: 0.75em;
    }
    .ico {
        color: var(--blue);
        width: 25px;
    }
}

@media screen and (max-width: 767px) {
    .event-velue-list {
        .button-title {
            margin: 0 auto .5em;
        }
    }
    .info-venue {
        display: flex;
        justify-content: center;
        align-items: center;
        .venue-list {
            margin: .75em 7px
        }
    }
}
