.btn-link{
    display: flex;
    align-items: center;
    width: fit-content;
    $height: 40px;
    transform-origin: center;
    .btn-learn{
        border-radius: 20% 20% 15% 15% / 36% 43% 35% 50%;
        min-width: 150px;
        background-color: var(--yellow);
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        font-style: italic;
        font-weight: 900;
        letter-spacing: .8px;
        color: var(--blue);
        width: fit-content;
        padding: 0 1em;
        height: $height;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Poppins', 'Roboto', Arial, Helvetica, sans-serif;
        &.bg-orange{
            background-color: var(--orange);
            color: white;
        }
        &.bg-blue{
            background-color: var(--blue);
            color: white;
        }
        &.bg-white{
            background-color: white;
            min-width: unset;
            padding-left: 0;
        }
    }
    .arrow{
        height: calc($height - 3px);
        width: calc($height - 3px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--blue);
        color: white;
        font-size: 1.25rem;
        margin-left: .5em;
        &.bg-orange{
            background-color: var(--orange);
        }
    }
    &:hover{
        animation: bounce 0.5s ease-out 1;
    }
}
.submit-btn{
    transform: rotate(-3deg);
    cursor: pointer;
    &:hover{
        animation: rotate .5s;
    }
}
@keyframes bounce {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.075);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(-3deg);
    }

    50% {
        transform: rotate(3deg) scale(1.1);
    }

    100% {
        transform: rotate(-3deg);
    }
}