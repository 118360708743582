.nowrap {
  white-space: nowrap;
}
a {
  color: inherit;
  transition: all 0.3s;

  &,
  &:link,
  &:visited {
    text-decoration: none;
  }
}

div {
  &.clear {
    clear: both;
  }
}
html {
  scroll-behavior: smooth;
}

body {
  // color: #606060;
  color: #333333;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  p {
    line-height: 1.8;
  }
  &.overflow {
    overflow: hidden;
  }
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

@import "lib";

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.container {
  max-width: var(--global-container-width);
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  &.alt {
    max-width: 1200px;
  }

  @media (max-width: 991px) {
    max-width: 700px;
  }
}
.relative {
  position: relative;
}
.section {
  padding: 100px 0;
  position: relative;
  &.no-padding {
    padding: 0;
  }
  &.pb-0 {
    padding-bottom: 0;
  }
  &.pt-0 {
    padding-top: 0;
  }
  &.pt-1 {
    padding-top: 1em;
  }
  &.pt-2 {
    padding-top: 2em;
  }
  &.pb-0 {
  }
  padding-bottom: 0;
  &.pb-1 {
  }
  padding-bottom: 1em;
  &.pb-2 {
  }
  padding-bottom: 2em;
  &.yellow {
    background-color: var(--yellow);
  }
  &.overflow-hidden {
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }
  .ornaments {
    height: 250px;
    .left {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 500px;
    }
    .right {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 500px;
      img {
        margin-left: auto;
      }
    }
    @media (max-width: 991px) {
      .left,
      .right {
        width: 350px;
      }
    }
    @media screen and (max-width: 767px) {
      height: 100px;
      .left,
      .right {
        width: 180px;
      }
    }
    img {
      // width: 100%;
      width: 75%;
    }
  }

  .big-heading {
    font-family: Poppins;
    font-weight: 900;
    text-align: center;
    position: relative;
    z-index: 1;

    &.margin-bottom {
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .sub-title {
      color: var(--blue);
      font-size: 28px;
      margin-bottom: 15px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    h2 {
      // font-size: 70px;
      font-size: 3.5rem;
      line-height: 1;

      @media (max-width: 991px) {
        font-size: 50px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 1.3;
      }
      span {
        display: block;
        &.white {
          color: white;
          &.anim {
            animation: text-color-anim 2s 0s infinite ease-in-out alternate;
          }
        }
        &.orange {
          color: var(--orange);
          &.anim {
            animation: text-color-anim 2s 0.6666s infinite ease-in-out alternate;
          }
        }
        &.blue {
          color: var(--blue);
          &.anim {
            animation: text-color-anim 2s 1.332s infinite ease-in-out alternate;
          }
        }
        &.inline-flex {
          display: inline-flex;
        }
      }
      &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
        .sub-title {
          margin-right: 15px;
        }
        @media (max-width: 991px) {
          flex-direction: column;
          .sub-title {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
      .sub-title {
        margin-bottom: 0;
      }
      a {
        color: #52c9e7;
      }
      .detail-arrow {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background-color: var(--yellow);
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        &:hover {
          &:before {
            transform: scale(15);
          }
        }
        &:before {
          content: "";
          z-index: 1;
          background-color: var(--orange);
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          right: -20px;
          bottom: -20px;
          transition: all 0.5s;
        }
        &:after {
          font-family: "Font Awesome 6 Pro";
          color: var(--blue);
          font-weight: 900;
          font-size: 26px;
          content: "\f178";
          position: relative;
          z-index: 2;
        }
        @media screen and (max-width: 767px) {
          height: 50px;
          width: 50px;
        }
      }
    }
  }
}

.text-center{
  text-align: center;
}

.swiper {
  .swiper-button-next {
    height: 60px;
    width: 60px;
    background-color: var(--yellow);
    border-radius: 50px;

    &:after {
      font-family: "Font Awesome 6 Pro";
      color: var(--blue);
      font-weight: 900;
      font-size: 18px;
      content: "\f105";
    }

    &:hover {
      animation: wiggle 0.8s ease-out 0s 1;
    }
  }
  .swiper-button-prev {
    height: 60px;
    width: 60px;
    background-color: var(--yellow);
    border-radius: 50px;
    // animation: wiggle 0.8s ease-out 0s 1s infinite;
    &:after {
      font-family: "Font Awesome 6 Pro";
      color: var(--blue);
      font-weight: 900;
      font-size: 18px;
      content: "\f104";
    }
    &:hover {
      animation: wiggle 0.8s ease-out 0s 1;
    }
  }
  .swiper-pagination {
    bottom: 40px;
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: var(--blue);
      width: 16px;
      height: 16px;
      border-radius: 5px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        transition: all 0.3s;
        background-color: var(--yellow);
        opacity: 0;
      }
      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 40px;
    @media screen and (max-width: 575px) {
      bottom: 0;
      transform: scale(0.5);
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: var(--blue);
      width: 20px;
      height: 20px;
      border-radius: 5px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        transition: all 0.3s;
        background-color: var(--yellow);
        opacity: 0;
      }

      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .swiper-button-next,
    .swiper-button-prev {
      height: 40px;
      width: 40px;
    }
    .swiper .swiper-horizontal > .swiper-pagination-bullets,
    .swiper .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper .swiper-pagination-custom,
    .swiper .swiper-pagination-fraction {
      bottom: 20px;
    }
    .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
    .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 3px;
    }
  }
}
.card-custom-swiper {

  .swiper-pagination {
    bottom: 40px;
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: var(--blue);
      width: 16px;
      height: 16px;
      border-radius: 5px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        transition: all 0.3s;
        background-color: var(--yellow);
        opacity: 0;
      }
      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
    @media screen and (max-width: 575px) {
      bottom: 0;
      transform: scale(0.5);
    }
  }
  .swiper-pagination {
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: var(--blue);
      width: 15px;
      height: 15px;
      border-radius: 5px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        transition: all 0.3s;
        background-color: var(--yellow);
        opacity: 0;
      }

      &.swiper-pagination-bullet-active {
        &:after {
          opacity: 1;
        }
      }
    }
  }
  .swiper-slide-shadow{
    border-radius: 50%;
  }
}
.page-content {
  margin-top: 150px;
  @media (max-width: 991px) {
    margin-top: 65px;
  }
}

.float-anim {
  animation: floating 6s 0s infinite linear;
}

@keyframes wiggle {
  25% {
    transform: scale(0.8, 1.3);
  }

  50% {
    transform: scale(1.1, 0.8);
  }

  75% {
    transform: scale(0.7, 1.2);
  }
}

@keyframes text-color-anim {
  0% {
    color: white;
  }
  50% {
    color: var(--orange);
  }
  100% {
    color: var(--blue);
  }
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(25px);
  }
  75% {
    transform: translateY(-25px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes floating-small {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(12px);
  }
  75% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

.button-title {
  color: white;
  // border-radius: 20% 20% 15% 15% / 36% 43% 35% 50%;
  border-radius: 10% 10% 10% 10% / 40% 40% 40% 40%;
  background-color: var(--blue);
  width: fit-content;
  font-weight: bold;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 2.25rem;
  padding: 0.25em 1.35em;
  border: none;
  position: relative;
  text-align: center;
  &.bg-yellow {
    background-color: var(--yellow);
    color: var(--blue);
  }
  &.bg-orange {
    background-color: var(--orange);
    color: white;
  }
  &.bg-lightblue {
    background-color: var(--lightblue);
    color: var(--blue);
  }
  &.text-normal {
    font-size: 1.25rem;
    padding: 0.25em 0.65em;
  }
  &.text-medium {
    font-size: 2rem;
    // padding: .25em .65em;
  }
  &.top-center {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &.top-left {
    position: absolute;
    left: 1em;
    top: 0;
    transform: translateY(-50%);
  }
  &.disabled {
    background-color: #d0d0d0;
    color: #e6e6e6;
  }
  @media screen and (max-width: 767px) {
    padding: 0.2em 1em;
    font-size: 2rem;
  }
}

.on-link:hover {
  animation: bouncee 0.6s;
}

.splash-bottom {
  position: absolute;
  bottom: 0;
  width: 300px;
  left: 0;
  transform: translateY(40%);
  animation: updown infinite 7s linear;
  @media screen and (max-width: 767px) {
    bottom: -25px;
  }
}

@keyframes updown {
  0% {
    transform: translateY(40%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(40%);
  }
}

@keyframes bouncee {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}

.banner-curve {
  z-index: 2;
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotateZ(180deg);
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    left: 50%;
    height: 75px;
    width: 100%;
    transform: translate(-50%, 50%);
    box-shadow: 0px 0px 0px 200px var(--yellow);
    border-radius: 50%;
    transition: all 0.3s;
    z-index: -1;
  }
  &.on-top {
    bottom: unset;
    top: 0;
    transform: unset;
  }
  &.white {
    &:after {
      box-shadow: 0px 0px 0px 200px white;
    }
  }
}

.detail-page,
.content-limit {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 2em 0.5em;
  .title-div {
    padding-right: 3em;
    @media screen and (max-width: 767px) {
      padding-right: 0;
    }
    .date {
      font-size: 1.125rem;
    }
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    width: 35%;
  }
  .content-div {
    width: 65%;
    line-height: 1.6;
    position: relative;
    z-index: 2;
    p {
      margin-bottom: 1em;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.5rem;
    }
    img {
      width: 100%;
      border-radius: 5px;
      margin-bottom: 1em;
    }
    ul,
    ol {
      line-height: 1.6;
      padding-left: 1em;
      margin-bottom: 1.25em;
      li {
        margin-bottom: 0.5em;
        margin-left: 0;
        padding-left: 0.5em;
      }
    }
    table {
      width: 100%;
      margin-bottom: 2em;
      tr.with-border th {
        // min-width: 100px;
        border-bottom: 5px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(
          90deg,
          #7ac4e5 0%,
          #7ac4e5 33%,
          var(--yellow) 33%,
          var(--yellow) 66%,
          var(--orange) 66%,
          var(--orange) 100%
        );
        &:first-child {
          width: 22%;
          border-image-source: linear-gradient(90deg, var(--blue) 0%, var(--blue) 100%);
        }
        &:last-child {
          border-image-source: linear-gradient(90deg, #e5333a 0%, #e5333a 100%);
          width: 22%;
        }
      }
      td,
      th {
        padding: 0.75em;
        border-bottom: 1px solid #d2d2d2;
      }
      tr:last-child {
        td {
          border-bottom: 5px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(
            90deg,
            #7ac4e5 0%,
            #7ac4e5 33%,
            var(--yellow) 33%,
            var(--yellow) 66%,
            var(--orange) 66%,
            var(--orange) 100%
          );
          &:first-child {
            border-image-source: linear-gradient(90deg, var(--blue) 0%, var(--blue) 100%);
          }
          &:last-child {
            border-image-source: linear-gradient(90deg, #e5333a 0%, #e5333a 100%);
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 1em;
    .content-div,
    .title-div {
      width: 100%;
    }
  }
}
.page-wrapper {
  overflow: hidden;
  .wrapper-route {
    margin-top: 150px;
    @media (max-width: 991px) {
      margin-top: 65px;
    }
  }
}
.page-content {
  transition: opacity 0.1s ease, transform 0.5s ease;
  &.fade-transition-enter {
    opacity: 0;
    transform: translateY(200px) scale(1.15);
    & > * {
      opacity: 0;
    }
  }
  &.fade-transition-enter-done {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
// .page-content {
//   &.fade-transition-enter {
//     &.fade-transition-enter-active{
//       animation: fade1 1s ease-in-out;
//     }
//   }
//   &.fade-transition-exit {
//     opacity: 0;
//     &.fade-transition-exit-active{
//       // background-color: blue;
//       animation: fade2 .6s ease-in-out;
//     }
//   }
//   &.fade-transition-enter-done{
//     // background-color: green;;
//   }
// }

@keyframes fade1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade2 {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.top-wave-yellow {
  position: absolute;
  top: 2px;
  transform: translateY(-100%);
  z-index: 2;
  left: 0;
  width: 100%;
  overflow: hidden;
  img {
    width: 100vw;
  }
}

.form {
  $gap: 1rem;
  $margin: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  width: 85%;
  // margin: 0 calc($gap / -2);
  justify-content: center;
  .form-control {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    &.textarea {
      align-items: baseline;
    }
    &.error {
      border: 2px solid var(--orange);
    }
    .input-control {
      border: none;
      padding: 0.8em;
      padding-left: 0;
      font-size: 18px;
      font-weight: bold;
      color: var(--blue);
      width: calc(100% - $margin);
      font-family: "Poppins", Arial, Helvetica, sans-serif;
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--blue);
        font-size: inherit;
        // font-family: 'Poppins', Arial, Helvetica, sans-serif;
      }
    }
  }
  .icon-input {
    width: $margin;
    text-align: center;
  }
  textarea {
    height: 200px;
    resize: none;
  }
  .half {
    width: calc(50% - $gap);
    @media screen and (max-width: 767px) {
      width: calc(100% - $gap);
    }
  }
  .full {
    width: calc(100% - $gap);
    margin-left: 0.125rem;
  }
}

.card-article {
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #d2d2d2;
  transition: all 0.35s ease;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  .div-img {
    aspect-ratio: 7 / 6;
    overflow: hidden;
  }
  img {
    transition: all 0.35s ease;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .div-content {
    padding: 1em;
  }
  .card-p,
  .card-p p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    line-height: 1.5;
  }
  .card-title {
    font-size: 1.25rem;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: bold;
    transition: all 0.35s ease;
    margin-bottom: 0.4em;
    cursor: pointer;
    color: var(--blue);
    &:hover {
      color: var(--orange);
    }
  }
}

.top-wave-animation,
.bottom-wave-animation {
  height: 120px;
  width: 100%;
  &::before,
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 101%;
    // background-size: 80%;
    background-size: auto;
    background-repeat: repeat-x;
    animation: wavemove 25s linear infinite alternate-reverse;
    background-image: url("../src/assets/lightblue-wave-infinite.svg");
  }
  &::after {
    background-image: url("../src/assets/blue-wave-infinite.svg");
    animation: wavemove2 25s linear infinite alternate-reverse;
  }
}
.bottom-wave-animation {
  transform: scale(-1);
}

@keyframes wavemove {
  0% {
    background-position: 100 0;
  }
  100% {
    background-position: 1000% 0;
  }
}
@keyframes wavemove2 {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: -1000% 0;
  }
}

.tips-div {
  $gap: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: calc($gap * 2);
  width: 100%;
  .tips-list {
    width: calc(50% - $gap);
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
  .icon-div {
    background-color: #5cc4ed;
    width: 30%;
    aspect-ratio: 1 / 1;
    border-radius: 5em / 1.5em;
    overflow: hidden;
    display: grid;
    place-items: center;
    img {
      width: 50%;
      object-fit: contain;
    }
  }
  .tips-content {
    width: 65%;
    p {
      font-size: 0.8rem;
    }
  }
}

.regulations-box {
  position: relative;
  // border-radius: 1em;
  .top-title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content {
    padding: 3em 1.5em 3em 3em;
    p {
      margin-bottom: 1em;
    }
    .div-content {
      max-height: 500px;
      overflow-y: scroll;
      padding-right: 1em;
      scrollbar-color: var(--blue) var(--yellow);
      scrollbar-width: thin;
      @media screen and (max-width: 756px) {
        max-height: 300px;
      }
      &::-webkit-scrollbar {
        width: 12px;
        height: auto;
      }
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px white;
        height: 20px;
        border-radius: 6px;
        background-color: var(--yellow);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--blue);
        border-radius: 6px;
        // -webkit-box-shadow: inset 2px 0 6px rgba(0,0,0,0.125);
      }
    }
  }
}
.borders-clip {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  div {
    background: var(--lightblue);
    position: absolute;
    left: 0;
    &:nth-child(1) {
      top: 0;
      width: 100%;
      height: 40px;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      transform: translateY(-50%);
    }
    &:nth-child(2) {
      top: 0;
      right: 0;
      width: 40px;
      left: unset;
      height: 100%;
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
      transform: translateX(50%);
    }
    &:nth-child(3) {
      bottom: 0;
      width: 100%;
      height: 40px;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      transform: translateY(50%);
    }
    &:nth-child(4) {
      top: 0;
      width: 40px;
      height: 100%;
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
      transform: translateX(-50%);
    }
  }
}

.spacer-footer {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
}

.cashless-div {
  img {
    transition: all 0.3s ease;
    width: 100%;
    margin-bottom: 1em;
    &:hover {
      opacity: 0.6;
    }
  }
}
.content,
.cashless-detail {
  ul,
  ol {
    padding-left: 0;
    li {
      margin: 0 1em 0.75em;
    }
  }
  .w-75 {
    @media screen and (max-width: 767px) {
      width: 95%;
    }
  }
  .top-wave-yellow img {
    width: 150vw;
    left: -100px;
    position: relative;
  }
}
#div-for-contact {
  border-radius: 6% / 50%;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 89%;
    height: 110%;
    background-color: #f9ee5e;
    z-index: -1;
    transform: translate(-50%, -50%);
    border-radius: 30% / 5%;
  }
}
.octo-ride-attract {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  img {
    width: 35%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .div-chat {
    background-color: var(--yellow);
    width: 65%;
    padding: 3em;
    border-radius: 8% / 50%;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      height: 30%;
      width: 30%;
      bottom: -20px;
      left: -5px;
      background-image: url("./assets/for-chat.svg");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 2em;
    }
  }
}
iframe {
  border: none;
  width: 100%;
  min-height: 880px;
  height: 880px;
  // height: 930px;
}

.syarat{
  ol, ul {
    margin-bottom: 1em;
    padding-left: 1em;
    line-height: 1.6;
    li{
      margin-bottom: .5em;
    }
  }
  p{
    margin-bottom: .75em;
  }
  @media screen and (max-width: 767px) {
    padding: 0 1em;
  }
}
.syaratcondition{
  ol, ul {
    margin-left: 1em;
    text-align: justify;
    margin-bottom: 1em;
    padding-left: 1em;
    line-height: 1.6;
    li{
      margin-bottom: .5em;
      text-align: justify;
    }
  }
  p{
    margin-bottom: .75em;
    text-align: justify;
  }
  h1{
    margin-bottom: 1em;
    text-align: center;
  }
 
  @media screen and (max-width: 767px) {
    padding: 0 1em;
  }
}

.map-legend {
  ol,
  ul {
    padding-left: 0;
    list-style-position: inside;
  }
  .wrapper {
    background: white;
    padding: 1em;
    border-radius: 6px;
    height: fit-content;
  }
}
.flex-dekstop {
  display: flex;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.map-getting-info {
  width: auto !important;
  @media screen and (max-width: 767px) {
    margin-top: 1em;
    width: 100% !important;
  }
}

.flashmessage {
  display: block;
  width: 100%;
  div {
    padding: 1em 1.5em;
    border-radius: 8px;
    margin-bottom: 1em;
    color: white;
    width: fit-content;
    background: #59c6e9;
  }
  &.error {
    div {
      background: #c03d29;
    }
  }
}

.pswp img {
  object-fit: contain;
}
.pswp__counter{
  display: none;
}

/* Checkmark style starts */
@-moz-keyframes 
dothabottomcheck {  0% {
 height: 0;
}
 100% {
 height: 6px;
}
}
@-webkit-keyframes 
dothabottomcheck {  0% {
 height: 0;
}
 100% {
 height: 6px;
}
}
@keyframes 
dothabottomcheck {  0% {
 height: 0;
}
 100% {
 height: 6px;
}
}
@keyframes 
dothatopcheck {  0% {
 height: 0;
}
 50% {
 height: 0;
}
 100% {
 height: 15px;
}
}
@-webkit-keyframes 
dothatopcheck {  0% {
 height: 0;
}
 50% {
 height: 0;
}
 100% {
 height: 15px;
}
}
@-moz-keyframes 
dothatopcheck {  0% {
 height: 0;
}
 50% {
 height: 0;
}
 100% {
 height: 15px;
}
}

.checkbox {
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 2px solid black; /* Adjust the border thickness if needed */
  border-radius: 2px; /* Adjust the border radius if needed */
  position: relative;
  display: inline-block;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: border-color ease 0.2s;
  -o-transition: border-color ease 0.2s;
  -webkit-transition: border-color ease 0.2s;
  transition: border-color ease 0.2s;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 5px;
}

.checkbox::before,
.checkbox::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  height: 0;
  width: 3px; /* Adjust the width based on the new checkbox size */
  background-color: #34b93d;
  display: inline-block;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  border-radius: 1px; /* Adjust the border radius if needed */
  content: ' ';
  -webkit-transition: opacity ease .5;
  -moz-transition: opacity ease .5;
  transition: opacity ease .5;
}

.checkbox::before {
  top: 11px; /* Adjust the top position based on the new checkbox size */
  left: 6px; /* Adjust the left position based on the new checkbox size */
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.checkbox::after {
  top: 6px; /* Adjust the top position based on the new checkbox size */
  left: 1px; /* Adjust the left position based on the new checkbox size */
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.checkbox.active { border-color: #34b93d; }

.checkbox.active::after {
  height: 15px; /* Adjust the height based on the new checkbox size */
  -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
  -o-animation: dothabottomcheck 0.2s ease 0s forwards;
  -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}

.checkbox.active::before {
  height: 15px; /* Adjust the height based on the new checkbox size */
  -moz-animation: dothatopcheck 0.4s ease 0s forwards;
  -o-animation: dothatopcheck 0.4s ease 0s forwards;
  -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
  animation: dothatopcheck 0.4s ease 0s forwards;
}


.term-content{
  max-width: 700px;
  .checkbox{
    flex: 1 0 auto;
  }
  .popup-click{
    cursor: pointer;
    color:  var(--blue);
  }
}

.ticket-button{
  &.disable{
    filter: grayscale(1);
  }
}

label.error{
  color: red;
  font-size: 13px;
}