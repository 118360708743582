.text-red {
    color: var(--orange);
}
.text-blue {
    color: var(--blue);
}
.text-yellow {
    color: var(--yellow);
}
.text-orange {
    color: var(--orange);
}
.text-white {
    color: white;
}
.text-light-blue {
    color: #52c9e7;
}
.text-medium {
    font-weight: 600 !important;
}

.text-bold {
    font-weight: bold !important;
}

.text-lighter {
    font-weight: 200 !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-smaller {
    font-size: 80%;
}

.text-italic {
    font-style: italic;
}

.font-sallomae {
    font-family: "Sallomae", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-grid {
    display: grid;
}

.d-none {
    display: none;
}

//SPACING
:root {
    --spacer: 0.25em;
    --bigspace: 1em;
    --space: 25px;
}
@for $i from 0 through 6 {
    .mb-#{$i} {
        margin-bottom: calc(var(--spacer) * #{$i});
    }
    .mt-#{$i} {
        margin-top: calc(var(--spacer) * #{$i});
    }
    .me-#{$i} {
        margin-right: calc(var(--spacer) * #{$i});
    }
    .ms-#{$i} {
        margin-left: calc(var(--spacer) * #{$i});
    }
    .mx-#{$i} {
        margin-left: calc(var(--spacer) * #{$i});
        margin-right: calc(var(--spacer) * #{$i});
    }
    .my-#{$i} {
        margin-top: calc(var(--spacer) * #{$i});
        margin-bottom: calc(var(--spacer) * #{$i});
    }
    .m-#{$i} {
        margin-left: calc(var(--spacer) * #{$i});
        margin-right: calc(var(--spacer) * #{$i});
        margin-top: calc(var(--spacer) * #{$i});
        margin-bottom: calc(var(--spacer) * #{$i});
    }

    .pb-#{$i} {
        padding-bottom: calc(var(--spacer) * #{$i});
    }
    .pt-#{$i} {
        padding-top: calc(var(--spacer) * #{$i});
    }
    .pe-#{$i} {
        padding-right: calc(var(--spacer) * #{$i});
    }
    .ps-#{$i} {
        padding-left: calc(var(--spacer) * #{$i});
    }
    .px-#{$i} {
        padding-left: calc(var(--spacer) * #{$i});
        padding-right: calc(var(--spacer) * #{$i});
    }
    .py-#{$i} {
        padding-top: calc(var(--spacer) * #{$i});
        padding-bottom: calc(var(--spacer) * #{$i});
    }
    .p-#{$i} {
        padding-left: calc(var(--spacer) * #{$i});
        padding-right: calc(var(--spacer) * #{$i});
        padding-top: calc(var(--spacer) * #{$i});
        padding-bottom: calc(var(--spacer) * #{$i});
    }
}
@for $i from 0 through 8 {
    .mb-big-min-#{$i} {
        margin-bottom: calc(var(--bigspace) * -#{$i} / 2);
    }
    .mt-big-min-#{$i} {
        margin-top: calc(var(--bigspace) * -#{$i} / 2);
    }

    .mb-big-#{$i} {
        margin-bottom: calc(var(--bigspace) * #{$i});
    }
    .mt-big-#{$i} {
        margin-top: calc(var(--bigspace) * #{$i});
    }
    .me-big-#{$i} {
        margin-right: calc(var(--bigspace) * #{$i});
    }
    .ms-big-#{$i} {
        margin-left: calc(var(--bigspace) * #{$i});
    }
    .mx-big-#{$i} {
        margin-left: calc(var(--bigspace) * #{$i});
        margin-right: calc(var(--bigspace) * #{$i});
    }
    .my-big-#{$i} {
        margin-top: calc(var(--bigspace) * #{$i});
        margin-bottom: calc(var(--bigspace) * #{$i});
    }
    .m-big-#{$i} {
        margin-left: calc(var(--bigspace) * #{$i});
        margin-right: calc(var(--bigspace) * #{$i});
        margin-top: calc(var(--bigspace) * #{$i});
        margin-bottom: calc(var(--bigspace) * #{$i});
    }

    .pb-big-#{$i} {
        padding-bottom: calc(var(--bigspace) * #{$i});
    }
    .pt-big-#{$i} {
        padding-top: calc(var(--bigspace) * #{$i});
    }
    .pe-big-#{$i} {
        padding-right: calc(var(--bigspace) * #{$i});
    }
    .ps-big-#{$i} {
        padding-left: calc(var(--bigspace) * #{$i});
    }
    .px-big-#{$i} {
        padding-left: calc(var(--bigspace) * #{$i});
        padding-right: calc(var(--bigspace) * #{$i});
    }
    .py-big-#{$i} {
        padding-top: calc(var(--bigspace) * #{$i});
        padding-bottom: calc(var(--bigspace) * #{$i});
    }
    .p-big-#{$i} {
        padding-left: calc(var(--bigspace) * #{$i});
        padding-right: calc(var(--bigspace) * #{$i});
        padding-top: calc(var(--bigspace) * #{$i});
        padding-bottom: calc(var(--bigspace) * #{$i});
    }
    .div-space-#{$i} {
        height: calc(var(--space) * #{$i});
    }
}

@media screen and (max-width: 767px) {
    @for $i from 0 through 6 {
        .mbl-mb-big-min-#{$i} {
            margin-bottom: calc(var(--bigspace) * -#{$i} / 2);
        }
        .mbl-mt-big-min-#{$i} {
            margin-top: calc(var(--bigspace) * -#{$i} / 2);
        }

        .mbl-mb-big-#{$i} {
            margin-bottom: calc(var(--bigspace) * #{$i});
        }
        .mbl-mt-big-#{$i} {
            margin-top: calc(var(--bigspace) * #{$i});
        }
        .mbl-me-big-#{$i} {
            margin-right: calc(var(--bigspace) * #{$i});
        }
        .mbl-ms-big-#{$i} {
            margin-left: calc(var(--bigspace) * #{$i});
        }
        .mbl-mx-big-#{$i} {
            margin-left: calc(var(--bigspace) * #{$i});
            margin-right: calc(var(--bigspace) * #{$i});
        }
        .mbl-my-big-#{$i} {
            margin-top: calc(var(--bigspace) * #{$i});
            margin-bottom: calc(var(--bigspace) * #{$i});
        }
        .mbl-m-big-#{$i} {
            margin-left: calc(var(--bigspace) * #{$i});
            margin-right: calc(var(--bigspace) * #{$i});
            margin-top: calc(var(--bigspace) * #{$i});
            margin-bottom: calc(var(--bigspace) * #{$i});
        }

        .mbl-pb-big-#{$i} {
            padding-bottom: calc(var(--bigspace) * #{$i});
        }
        .mbl-pt-big-#{$i} {
            padding-top: calc(var(--bigspace) * #{$i});
        }
        .mbl-pe-big-#{$i} {
            padding-right: calc(var(--bigspace) * #{$i});
        }
        .mbl-ps-big-#{$i} {
            padding-left: calc(var(--bigspace) * #{$i});
        }
        .mbl-px-big-#{$i} {
            padding-left: calc(var(--bigspace) * #{$i});
            padding-right: calc(var(--bigspace) * #{$i});
        }
        .mbl-py-big-#{$i} {
            padding-top: calc(var(--bigspace) * #{$i});
            padding-bottom: calc(var(--bigspace) * #{$i});
        }
        .mbl-p-big-#{$i} {
            padding-left: calc(var(--bigspace) * #{$i});
            padding-right: calc(var(--bigspace) * #{$i});
            padding-top: calc(var(--bigspace) * #{$i});
            padding-bottom: calc(var(--bigspace) * #{$i});
        }
        .mbl-div-space-#{$i} {
            height: calc(var(--space) * #{$i});
        }
    }
    .d-mbl-none {
        display: none !important;
    }
    .mbl-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.min-125 {
    margin-bottom: -125px;
    @media screen and (max-width: 767px) {
        margin-bottom: -50px;
    }
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.w-100 {
    width: 100%;
}
.w-75 {
    width: 75%;
}
.w-50 {
    width: 50%;
}

.main-title {
    font-size: clamp(1.76rem, calc(1.27rem + 1.45vw), 2.73rem);
}
.secondary-title {
    font-size: clamp(1.33rem, calc(1rem + 0.81vw), 1.88rem);
    line-height: 1.3;
}
.third-title {
    font-size: clamp(0.94rem, calc(0.88rem + 0.28vw), 1.13rem);
}
.big-title {
    font-size: clamp(1.88rem, calc(1.6rem + 1.39vw), 2.81rem);
}
.extra-big-title {
    font-size: clamp(1.88rem, calc(3.6rem + 1.39vw), 4.5rem);
}

.centered-items {
    & > * {
        margin: 0 auto;
    }
}
.bigger {
    transform: scale(1.5);
}
.z-index {
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .mbl-hide {
        display: none;
    }
    .w-mbl-100 {
        width: 100%;
    }
}

$colors: (
    "yellow": var(--yellow),
    "blue": var(--blue),
    "orange": var(--orange),
    "lightblue": var(--lightblue),
    "green": var(--green),
    "lightgreen": var(--lightgreen),
    "purple": #b18ae2,
);

@each $name, $color in $colors {
    .bg-color-#{$name} {
        background-color: $color !important;
    }
}

@for $i from 1 through 5 {
    .spacer-#{$i} {
        height: calc(50px * #{$i});
    }
}

$gap-layout: 30px;
.layout-3,
.layout-6,
.layout-2 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: $gap-layout;
    & > div {
        width: calc(33% - $gap-layout);
        @media screen and (max-width: 991px) {
            width: calc(50% - $gap-layout);
        }
        @media screen and (max-width: 756px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 756px) {
        &.mbl-layout-2 {
                & > div {
                    width: calc(50% - $gap-layout);
                }
        }
        &.mbl-layout-3 {
                & > div {
                    width: calc(33% - $gap-layout);
                }
        }
        &.mbl-layout-6 {
                & > div {
                    width: calc(16.5% - $gap-layout);
                }
        }
    }
}
.layout-6 {
    & > div {
        width: calc(16.5% - $gap-layout);
        @media screen and (max-width: 991px) {
            width: calc(33% - $gap-layout);
        }
        @media screen and (max-width: 756px) {
            width: calc(50% - $gap-layout);
        }
    }
}
.layout-2 {
    & > div {
        width: calc(50% - $gap-layout);
        @media screen and (max-width: 756px) {
            width: 100%;
        }
    }
}
.justify-content-center {
    justify-content: center;
}

.border-radius {
    border-radius: 1em;
}
.overflow-hidden {
    overflow: hidden;
}

.dekstop-only {
    display: block;
    @media screen and (max-width: 757px) {
        display: none;
    }
}

.mobile-only {
    display: none;
    @media screen and (max-width: 757px) {
        display: block;
    }
}
.flex-center{
    display: flex;
    justify-content: center;
}
