.dining-div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
        // max -width: 50%;
        width: 50%;
        @media screen and (max-width: 767px) {
            // max-width: 100%;
            width: 100%;
        }
    }
    &:nth-of-type(odd) {
        .dining-img {
            order: 1;
            transform: translateX(30px);
        }
        .dining-list {
            order: 2;
            transform: translateX(-30px) rotate(-2deg);
        }
    }
    &:nth-of-type(even) {
        .dining-img {
            order: 2;
            transform: translateX(-30px);
        }
        .dining-list {
            order: 1;
            transform: translateX(30px) rotate(2deg);
        }
    }
    &.for-map {
        .dining-img {
            order: 1;
            transform: translateX(30px);
        }
        .dining-list {
            order: 2;
            transform: translateX(-30px);
            display: block;
        }
        .logo_ {
            width: 180px;
            object-fit: contain;
            margin-right: 1.5em;
            margin-bottom: 1em;
        }
        .address {
            display: flex;
            color: white;
            margin: 20px 0;
            @media screen and (max-width: 992px) {
                display: block;
            }
            .contact {
                // flex: 0 0 40%;
                margin-right: 1em;
                .single-contact {
                    display: flex;
                    align-items: center;
                    padding: 3px 0;
                    .icon {
                        width: 30px;
                        color: var(--blue);
                    }
                    .text {
                        transition: all 0.3s;
                        color: white;
                    }
                    &:hover {
                        .text {
                            color: var(--blue);
                        }
                    }
                }
            }
        }
    }
    &.map-getting-here{
        flex-direction: column;
    }
    .dining-img {
        cursor: pointer;
        &.no-pointer{
            cursor: auto;
        }
    }
    .dining-list {
        background-color: var(--lightblue);
        position: relative;
        padding: 1.5em;
        border-radius: 1em;
        // min-height: 175px;
        min-height: 215px;
        display: flex;
        align-items: center;
        z-index: 2;
        p {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
        }
        .button-title {
            margin: auto;
            position: absolute;
            text-align: center;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 55%;
        }
    }
    .link-center {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }
    @media screen and (max-width: 767px) {
        &:nth-of-type(odd),
        &:nth-of-type(even) {
            .dining-img {
                order: 1;
            }
            .dining-list {
                order: 2;
                padding: 2.5em 1.35em;
            }
            .dining-img,
            .dining-list {
                transform: translateX(0) rotate(0);
            }
        }
        & > div {
            max-width: 100%;
        }
    }
}
.no-center{
    .dining-div .dining-list {
        align-items: start;
    }
}
.services-page {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .dining-div {
        &:nth-of-type(odd),
        &:nth-of-type(even) {
            .dining-img {
                order: 1;
            }
            .dining-list {
                order: 2;
            }
            .dining-img,
            .dining-list {
                transform: translateX(0) rotate(0);
            }
        }
        & > div {
            width: 100%;
        }
        .dining-list {
            background-color: transparent;
            .button-title {
                @media screen and (max-width: 767px) {
                    max-width: 70%;
                    font-size: 1.125rem;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            @media screen and (max-width: 767px) {
                margin-bottom: -2em;
            }
        }
        max-width: 50%;
        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }
}

.plan-page {
    .dining-list {
        background-color: var(--blue);
        color: white;
    }
}
