.wave-section {
    display: grid;
    place-items: center;
    min-height: 500px;
    position: relative;
    .wave {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        object-fit: cover;
        height: 100%;
    }
    &.flip {
        img {
            transform: scaleX(-1);
        }
    }
}

.path-wave {
    // animation: pathAnim-0 10s linear infinite;
}
#path2 {
    position: absolute;
    z-index: -1;
    bottom: 0;
    .path-wave {
        // animation: pathAnim-0 12s linear infinite;
    }
}
#path1 {
    position: absolute;
    bottom: 30px;
}
.first-banner {
    position: relative;
    min-height: 450px;
    display: grid;
    place-items: center;
    overflow: hidden;
    @media screen and (max-width: 500px) {
        svg{
            height: 100px;
        }
    }
    @media screen and (max-width: 767px) {
        svg{
            height: 150px;
        }
    }
    .main-color {
        background-color: #7ac5e5;
        width: 100%;
        height: 60%;
        position: absolute;
        top: 0;
        left: 0;
        @media screen and (max-width: 767px) {
            height: calc(100% - 100px);
        }
    }
    .content-first-banner {
        margin-top: -70px;
        z-index: 1;
        position: relative;
    }
}
@keyframes pathAnim-0 {
    0% {
        d: path(
            "M 0,400 C 0,400 0,200 0,200 C 197.46666666666664,218.53333333333333 394.9333333333333,237.06666666666666 555,225 C 715.0666666666667,212.93333333333334 837.7333333333333,170.26666666666668 979,161 C 1120.2666666666667,151.73333333333332 1280.1333333333332,175.86666666666667 1440,200 C 1440,200 1440,400 1440,400 Z"
        );
    }

    25% {
        d: path(
            "M 0,400 C 0,400 0,200 0,200 C 137.33333333333331,163.06666666666666 274.66666666666663,126.13333333333335 415,145 C 555.3333333333334,163.86666666666665 698.6666666666667,238.53333333333336 870,257 C 1041.3333333333333,275.46666666666664 1240.6666666666665,237.73333333333332 1440,200 C 1440,200 1440,400 1440,400 Z"
        );
    }

    50% {
        d: path(
            "M 0,400 C 0,400 0,200 0,200 C 168,175.46666666666667 336,150.93333333333334 483,137 C 630,123.06666666666668 756,119.73333333333335 912,132 C 1068,144.26666666666665 1254,172.13333333333333 1440,200 C 1440,200 1440,400 1440,400 Z"
        );
    }

    75% {
        d: path(
            "M 0,400 C 0,400 0,200 0,200 C 163.46666666666664,234.53333333333333 326.9333333333333,269.06666666666666 475,267 C 623.0666666666667,264.93333333333334 755.7333333333333,226.2666666666667 914,209 C 1072.2666666666667,191.7333333333333 1256.1333333333332,195.86666666666665 1440,200 C 1440,200 1440,400 1440,400 Z"
        );
    }

    100% {
        d: path(
            "M 0,400 C 0,400 0,200 0,200 C 197.46666666666664,218.53333333333333 394.9333333333333,237.06666666666666 555,225 C 715.0666666666667,212.93333333333334 837.7333333333333,170.26666666666668 979,161 C 1120.2666666666667,151.73333333333332 1280.1333333333332,175.86666666666667 1440,200 C 1440,200 1440,400 1440,400 Z"
        );
    }
}
