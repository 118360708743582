footer {
    // background-color: var(--blue);
    position: relative;
    z-index: 1;
    .col-row {
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        padding-bottom: 50px;
        flex-wrap: wrap;
        &.no-pad {
            padding-bottom: 0;
        }
        .col {
            position: relative;
            padding: 0 15px;
            flex: 0 0 25%;
            @media (max-width: 991px) {
                flex: 0 0 33.33%;
                &.open-hours-col {
                    flex: 0 0 100%;
                    margin-bottom: 50px;
                    order: -1;
                }
            }
            @media (max-width: 767px) {
                flex: 0 0 100%;
                margin-bottom: 20px;
                text-align: center;
            }
        }
        .col-2 {
            padding: 0 15px;
            flex: 0 0 50%;
            @media (max-width: 991px) {
                flex: 0 0 100%;
            }
        }
    }
    .top-wave {
        overflow: hidden;
        position: relative;
        width: 100%;
        top: 0;
        height: 165px;
        @media (max-width: 756px) {
            height: 100px;
        }
    }
    .footer-wave {
        background-position: top;
        background-size: 1280px 525px;
        width: 5400px;
        height: 150px;
        position: relative;
        background-repeat: repeat-x;
        position: absolute;
        bottom: 0;
        @media (max-width: 767px) {
            background-size: 320px 130px;
            height: 70px;
        }
    }
    .bottom-splash {
        width: 350px;
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            width: 100%;
        }
        .socmed {
            position: absolute;
            right: 15px;
            bottom: 15px;
        }
        .copyright-section {
            display: none;
        }
        @media (max-width: 767px) {
            width: 100%;
            position: unset;
            background-color: var(--blue);
            padding: 0 15px 15px 15px;
            & > img {
                display: none;
            }
            .socmed {
                position: relative;
                right: inherit;
                bottom: inherit;
                margin-bottom: 15px;
            }
            .mobile-spacer {
                height: 80px;
            }
            .copyright-section {
                display: block;
                padding-bottom: 0;
                text-align: center;
                font-size: 12px;
            }
        }
    }
    .wave1 {
        // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
        background-image: url(./footer-wave.svg);
        // background-size: 3000px 300px;

        z-index: 2;
        animation: footer-wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, footer-swell 5s ease-in-out infinite;
        @media (max-width: 767px) {
            animation: footer-wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite,
                footer-swell 5s ease-in-out infinite;
        }
        // transform: translate3d(0, 0, 0);
    }
    .wave2 {
        // background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
        background-image: url(./footer-wave2.svg);
        // background-size: 3000px 300px;
        // background-position: top;
        // background-size: 1350px 525px;
        // width: 5400px;
        // height: 100px;
        // position: relative;
        animation: footer-wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, footer-swell2 6s ease-in-out infinite;
        left: 0;
        bottom: 20px;
        @media (max-width: 767px) {
            animation: footer-wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite,
                footer-swell2 6s ease-in-out infinite;
        }
        // transform: translate3d(0, 0, 0);
    }
    .hello {
        animation: floating-small 4s 0s infinite linear;
        position: fixed;
        right: 50px;
        width: 100px;
        bottom: 50px;
        z-index: 3;
        @media (max-width: 767px) {
            bottom: 50px;
            right: 10px;
            width: 60px;
        }
        svg {
            * {
                transform-origin: center center;
                transform-box: fill-box;
            }
        }
        .bubble {
            width: 70px;
            position: absolute;
            left: -50px;
            top: -10px;
            overflow: visible;
            filter: drop-shadow(0px 0px 1px #e6d930);
            @media (max-width: 767px) {
                width: 40px;
                left: -20px;
                top: -5px;
            }
            svg {
                transform: scale(0);
                #H,
                #E,
                #L-1,
                #L-2,
                #O {
                    transform: scale(0);
                }
            }
            &.pop {
                svg {
                    transform-origin: center;
                    animation: bubble-pop 0.5s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    #H {
                        animation: bubble-pop 0.3s 0.3s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    }
                    #E {
                        animation: bubble-pop 0.3s 0.5s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    }
                    #L-1 {
                        animation: bubble-pop 0.3s 0.7s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    }
                    #L-2 {
                        animation: bubble-pop 0.3s 0.9s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    }
                    #O {
                        animation: bubble-pop 0.3s 1.1s cubic-bezier(0, 0.64, 0.42, 1.58) 1 forwards;
                    }
                }
            }
        }
        .mascot {
            img {
                width: 100%;
            }
        }
    }
    .footer-columns {
        background-color: var(--blue);
        .main-menu {
            font-family: Poppins;
            font-weight: 900;
            color: white;
            font-size: 24px;
            display: block;
            // margin-bottom: 15px;
            margin-bottom: .5em;
            @media (max-width: 991px) {
                font-size: 20px;
            }
            &:last-child {
                margin-bottom: 0px;
            }
            &:hover, &.active {
                color: var(--yellow);
            }
        }
        .sub-menu-col {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
        }
        .sub-menu {
            font-family: Poppins;
            font-weight: 300;
            color: white;
            display: block;
            font-size: 15px;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0px;
            }
            &:hover, &.active {
                color: var(--yellow);
            }
        }
        h5 {
            display: block;
            color: var(--yellow);
            font-size: 24px;
            // margin-bottom: 20px;
            @media (max-width: 767px) {
                font-size: 18px;
                margin-bottom: 10px;
            }
        }
        .open-hours {
            font-family: Poppins;
            font-weight: 900;
            text-align: right;
            .hours {
                font-size: 38px;
                color: white;
                line-height: 1.3;
                @media (max-width: 767px) {
                    font-size: 26px;
                }
            }
            @media screen and (max-width: 767px) {
                text-align: center;
            }
        }

        .payments {
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
            img {
                width: 100%;
                max-width: 120px;
                @media (max-width: 767px) {
                    margin: 0 auto;
                }
            }
            h5 {
                span {
                    position: relative;
                    @for $i from 1 to 20 {
                        &:nth-child(#{$i}) {
                            animation: payment-text 2s #{calc($i / 17)}s forwards infinite;
                        }
                    }
                }
            }
        }
        .address {
            display: flex;
            color: white;
            width: 500px;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                width: 100%;
            }
            .logo {
                flex: 0 0 50%;
                padding-right: 30px;
                img {
                    max-width: 200px;
                    width: 100%;
                }
            }
            .text {
                // flex: 0 1 250px;
                // flex: 0 0 50%;
                line-height: 1.5;
            }
            .contact {
                flex: 0 0 50%;
                .single-contact {
                    display: flex;
                    align-items: center;
                    padding: 3px 0;
                    .icon {
                        width: 30px;
                        color: var(--yellow);
                    }
                    .text {
                        transition: all 0.3s;
                        color: white;
                    }
                    &:hover {
                        .text {
                            color: var(--yellow);
                        }
                    }
                }
            }
            @media (max-width: 767px) {
                display: block;
                text-align: center;
                .logo {
                    margin-bottom: 20px;
                    padding-right: 0;
                    img {
                        margin: 0 auto;
                    }
                }
                .contact {
                    .single-contact {
                        justify-content: center;
                    }
                }
            }
        }
    }
    .copyright-section {
        padding-bottom: 30px;
        display: flex;
        background-color: var(--blue);
        .copyright {
            font-size: 11px;
            color: white;
            @media screen and (max-width: 767px) {
                margin: 0 auto;
                width: 160px;
            }
            a{
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .socmed {
        margin-left: auto;
        margin-right: 0;
        display: flex;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        border-radius: 10px;
        padding: 5px 15px;
        background-color: white;
        @media (max-width: 767px) {
            justify-content: center;
        }
        a {
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 5px;
            &:hover {
                background-color: var(--yellow);
            }
            img {
                width: 20px;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
    &.bg-yellow{
        background-color: var(--yellow);
    }
}

@keyframes footer-wave {
    0% {
        margin-left: 0;
    }

    100% {
        margin-left: -1280px;
    }
}

@keyframes footer-swell {
    0%,
    100% {
        transform: translate3d(0, 10px, 0);
    }

    50% {
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes footer-swell2 {
    0%,
    100% {
        transform: translate3d(0, 15px, 0);
    }

    50% {
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes bubble-pop {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes payment-text {
    0% {
        transform: translateY(0);
        color: var(--yellow);
        top: 0;
    }
    20% {
        transform: translateY(20px);
        // color: var(--orange);
        color: white;
        top: -5px;
    }
    40% {
        transform: translateY(0);
        // color: #52c9e7;
        top: 0;
    }
    100% {
        transform: translateY(0);
        color: var(--yellow);
    }
}
