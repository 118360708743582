.my-loading-page{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--blue);
    z-index: 9999;
    color: white;
    display: grid;
    place-items: center;
}

.div-svg{
    position: absolute;
    width: min(60%, 300px);
    margin: 0 auto;
    height: 100%;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
}

.svg-fill{
    fill: transparent;
}

.loading-bar{
    width: min(60%, 300px);
    justify-content: center;
    display: flex;
    position: relative;
    &>.dotss{
        width: 15px;
        height: 15px;
        background-color: white;
        border-radius: 50%;
        margin: 0 5px;
        opacity: 1;
        &:nth-child(1){
            animation: loading 2s infinite;
        }
        &:nth-child(2){
            animation: loading 2s infinite;
            animation-delay: .15s;
        }
        &:nth-child(3){
            animation: loading 2s infinite;
            animation-delay: .3s;
        }
        &:nth-child(4){
            animation: loading 2s infinite;
            animation-delay: .45s;
        }
        &:nth-child(5){
            animation: loading 2s infinite;
            animation-delay: .6s;
        }
        // transition: all .3s ease;
    }
}
@keyframes loading {
    0%{
        transform: translateY(0);
        // opacity: 0;
    }
    25%{
        transform: translateY(7px);
    }
    50%{
        transform: translateY(-5px);
    }
    75%{
        transform: translateY(7px);
    }
    100%{
        transform: translateY(0px);
    }
}