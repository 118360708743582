.circular-slider-section {
    &.detail {
        height: 400px;
        overflow: hidden;
        @media screen and (max-width: 767px) {
            height: 300px;
        }
        .navigation-button {
            button {
                .arrow {
                    background-color: var(--orange);
                }
            }
            .button-next {
                .arrow {
                    &:after {
                        color: white;
                    }
                }
            }
            .button-prev {
                .arrow {
                    &:after {
                        color: white;
                    }
                }
            }
        }

        .circular-slider-container {
            position: relative;
            transform: scale(1.5);
            transform-origin: center top;
            .box {
                border-radius: 1em;
                overflow: hidden;
                aspect-ratio: 3/2;
                @media screen and (max-width: 767px) {
                    width: 240px;
                }
                .image {
                    height: 100%;
                    transition: all .35s ease;
                    background-size: cover;
                    &:hover{
                        transform: scale(1.05);
                        opacity: .8;
                    }
                }
            }
        }
    }
}
